import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Container, Row, Col, Carousel } from 'react-bootstrap';
import { GatsbyImage } from "gatsby-plugin-image"

const IndexPage = ({data}) => (
  <Layout>
    <Seo title="From Cottages to Castles" />
    <div>
      {/* {data.slideShow.edges.map(({node}) => (
          <div key={node.id}>
            
            <p><img src={node.publicURL} alt="a photo" className="img-fluid" /></p>
            <p><GatsbyImage image={node.childImageSharp.gatsbyImageData} alt="Piscatelli Roofs" /></p>
            <p>{node.base}
            </p>
          </div>
        ))} */}
    </div>
    <Container id="overview" className="dividers">
      <Row>
        <Col lg={6}>
          <h1>From Cottages to Castles</h1>
          <p>A family-owned and -operated company since 2017, Piscatelli Roofs serves homeowners and business owners in Marin, Alameda, Contra Costa, and San Francisco counties.</p>
          <h3>Commitment</h3>
          <p>We commit to and deliver top quality workmanship roofing services to every homeowner and business owner that hires Piscatelli Roofs. Our goal is always the same: to provide professionalism and excellence.</p>
        </Col>
        <Col lg={6}>
          <p><StaticImage src="../images/artwork.png" alt="A Sketch of a House" /></p>
        </Col>
      </Row>
      <h2>Services</h2>
      <p>From quick or emergency repairs, cleaning and winterization to re-roofs and new construction roofs, we gladly give personal service from before the job starts to after it has been completed. We promptly respond to customer calls / emails; review options and aesthetics with customers; and, we help them determine the most affordable and effective services and materials.</p>
      <Row>
        <Col lg={4}>
          <h3>Maintenance & Winterizing</h3>
          <p>We offer regular maintenance and winterization services by visiting your home and/or business once or twice a year to proactively team with you. Keeping up with roof repairs including quickly addressing leaks and damaged materials can add years to your roof!</p>
        </Col>
        <Col lg={4}>
          <h3>Coating</h3>
          <p>As a great alternative to re-roofing, we offer a coating that comes with a 20-year warranty, does not require permits, and is considerably less costly than most re-roofings. We would be glad to tell you more about the option of coating your roof as well as show you some photos of completed coating projects.</p>
        </Col>
        <Col lg={4}>
          <h3>Re-Roofing</h3>
          <p>Our re-roofing work encompasses many different materials: “torch down” aka Modified Bitumen, TPO / PVC, composition (asphalt) shingles), and coating, as described above. We also specialize in Spanish tile, slate, cedar shingles, and cedar shake.</p>
        </Col>
      </Row>
    </Container>
    <Container className="slide-show-max-width dividers" id="projects">
      <h2>Projects</h2>
      <Carousel controls={false}>
      {data.slideShow.edges.map(({node}) => (
          <Carousel.Item key={node.id}>
            <p className="text-center">
            <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt="Piscatelli Roofs" />
            </p>
          </Carousel.Item>         
      ))}
      </Carousel>
    </Container>
    <Container id="testimonials" className="dividers">
      <h2>How Our Customers Have Responded</h2>
      <Row>
        <Col lg={6}>
          <p><em>“I had some significant water leaks through our skylight system due to the recent rains. I contacted many roofing companies for assistance with no response or success. A neighbor on NextDoor recommended… Piscatelli Roofs… an expert in roof repairs and maintenance. He correctly analyzed the problems and during the breaks in the weather was able to take a difficult situation and resolve it. Leaks no more! I would highly recommend Piscatelli Roofs services!”</em></p>
          <p>– Steve L.</p>
        </Col>
        <Col lg={6}>
          <p><em>“Thanks again for this recommendation. We went with Piscatelli Roofs, and both my husband and I think he’s the greatest thing since sliced bread. Amazing, genuine, cool… and skilled beyond. Thank you, thank you.”</em></p>
          <p>– Meredith + Matt</p>
          <p><em>“I can’t even begin to describe what a great experience it has been working with Piscatelli Roofs. I can’t thank you enough for the referral. We had a serious leak, and they were was able to isolate the problem and fix it PLUS… the kind of person you feel like you’ve known forever.  We are very grateful.”</em></p>
          <p>– Vicki</p>
        </Col>
      </Row>
    </Container>
    <Container id="contact" className="dividers">
      <h2>Contact Us</h2>
      <p>E-mail: <a href="mailto:piscatelliroofs@gmail.com">piscatelliroofs@gmail.com</a><br />
Phone: (415) 342-4438</p>
    </Container>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql `
  query {
    slideShow: allFile(
      filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: {eq: "slide-show"}}
      sort: {fields: base, order: ASC}
    ) {
      edges {
        node {
          id
          base
          relativePath
          relativeDirectory
          sourceInstanceName
          publicURL
          childImageSharp {
            id
            gatsbyImageData(
              height: 600
              width: 600
              transformOptions: {fit: CONTAIN}
              backgroundColor: "#f9f6ef"
            )
          }
        }
      }
    }
  }
`